/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import { Hero3 } from 'components/elements'

const PageTemplate = ({
  data: {
    page: { path, title, content, yoast_meta: yoast },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <Hero3
        fields={{
          description: `<h1>${title}</h1>`
        }}
      />

      <div className="container py-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-10">
            <div className="px-lg-4">
              <ParseContent className="px-lg-3" content={content} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      content
      
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
